import { SelectablePieChart } from "./GroupPieChart";
import { SelectableChart as SelectableSeriesChart } from "./GroupSeriesChart";
import { SelectableChart as SelectableBarChart } from "./GroupStackedChart";
import { Query } from "../lib/api/charts";
import React from "react";

type GroupSelectableChartProps = {
  update: any;
  report: any;
  title: string;
  query: Query;
  chartOptions: any;
  initialChartType: "bar" | "line" | "pie" | "period";
  measure?: any;
  lockScorecardOptions?: boolean;
};
export const GroupSelectableChart: React.FC<GroupSelectableChartProps> = ({
  update,
  report,
  title,
  query,
  chartOptions,
  initialChartType,
  measure,
  lockScorecardOptions,
}) => {
  const [chartType, updateType] = React.useState(initialChartType);
  const updatedQuery = { ...query, meta: { chartType: chartType } };

  if (chartType === "pie") {
    return (
      <SelectablePieChart
        report={report}
        query={updatedQuery}
        title={title}
        handleSelection={update}
        handleChartType={chartType => {
          updateType(chartType);
        }}
        chartType={chartType}
        chartOptions={chartOptions}
        measure={measure}
      />
    );
  }
  if (chartType === "line") {
    return (
      <>
        <SelectableSeriesChart
          report={report}
          allowPieSelection={(chartOptions[report] && chartOptions[report].canAverage) || false}
          query={updatedQuery}
          title={title}
          lockScorecardOptions={lockScorecardOptions}
          handleSelection={update}
          handleChartType={chartType => {
            updateType(chartType);
          }}
          chartType={chartType}
          chartOptions={chartOptions}
          measure={measure}
        />
      </>
    );
  }
  return (
    <>
      <SelectableBarChart
        report={report}
        allowPieSelection={(chartOptions[report] && chartOptions[report].canAverage) || false}
        query={updatedQuery}
        lockScorecardOptions={lockScorecardOptions}
        handleSelection={update}
        chartOptions={chartOptions}
        handleChartType={chartType => {
          updateType(chartType);
        }}
        chartType={chartType}
        measure={measure}
      />
    </>
  );
};
